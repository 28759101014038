.gdpr_consent_dialog {
  @include header_background();
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100vw;
  padding: 1rem 0;
  bottom: 0;
  z-index: 50;
}
.gdpr_consent_content {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 30vw;
  @include mobile {
    margin: 0 5vw;
  }
  @include tablet {
    margin: 0 15vw;
  }
  @include desktop {
    margin: 0 25vw;
  }
  @include large_screen {
    margin: 0 30vw;
  }
  @include extra_large_screen {
    margin: 0 35vw;
  }
  @include basic-links;
}
.gdpr_consent_message {
  display: flex;
  flex-wrap: wrap;
  text-align: justify;
  overflow-wrap: break-word;
  flex-grow: 2;
  margin-bottom: 0.75rem;
  @include main_font_color;
}
.gdpr_consent_buttons_container{
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.75rem;
}
.gdpr_consent_button {
  @include background_success_color;
  @include inverse_main_color;
  border-radius: 1rem;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  flex-grow: 2;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  &:hover {
    cursor: pointer;
    @include background_darker_success_color;
  }
}
.gdpr_reject_link {
  &:hover {
    cursor: pointer;
  }
}
