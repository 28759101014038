#pricing-view {
   #pricing__pricing-cards-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .pricing-card__pricing--card  {
         .pricing-card-body__pricing-card--content {
            &.bold-secondary--color {
               ul {
                  li {
                     b {
                        @include secondary_color;
                     }
                  }
               }
            }
            &.bold-warning--color {
               ul {
                  li {
                     b {
                        @include warning_color;
                     }
                  }
               }
            }
            ul {
               padding-left: 1rem;
               list-style-type: none;
               @include main_font_color;
               li {
                  text-indent: -1.6rem;
                  b {
                     @include responsive_font_size(1.2rem, 1.2rem, 1.2rem, 1.2rem, 1.2rem);
                     @include text_simple_style(300, left);
                     @include main_font_color;
                  }
               }
            }
            .price-section__pricing-card--subtitle {
               p {
                  text-align: center !important;
               }
            }
         }
      }
   }
}
