@keyframes dropdown_display {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }

   0% {
      opacity: 0;
   }
}
@keyframes fade_in_static {
   from {
      opacity: 0;
      display: none;
   }
   to {
      opacity: 1;
      display: block;
   }
}
@keyframes fade_out_static {
   from {
      opacity: 1;
   }
   to {
      opacity: 0;
   }
}
@keyframes fade_in_bottom {
   from {
      opacity: 0;
      transform: translateY(50%);
   }
   to { opacity: 1 }
}
@keyframes fade_in_top_left {
   from {
      opacity: 0;
      transform: translateX(-5%) translateY(-50%);
   }
   to { opacity: 1 }
}
@mixin fade_in_static($duration) {
   animation: fade_in_static $duration 2s ease-in-out;
}
@mixin fade_out_static($duration) {
   animation: fade_out_static $duration 0.7s ease-in-out;
}
@mixin fade_in_from_bottom($duration) {
   animation: fade_in_bottom $duration 0.3s both;
}
@mixin fade_in_from_top_left($duration) {
   animation: fade_in_top_left $duration 0s ease-out;
}
