#faq-view {
   #infographics {
      margin-top: 0;
   }
   @include mobile {
   }
   .question {
      section {
         p {
            margin-bottom: 1rem;
         }
         span {
            border-radius: 2rem 0 0 2rem;
         }
      }
   }
}
