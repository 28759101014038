.contact_form {
   border-radius: 20px;
   padding: 1rem 2rem 2rem 2rem;
   font-size: 1rem;
   @include main_font_color;
   @include translucent_grey_background();
   @include responsive_width(100%, 90%, 70%, 50%, 40%);
   margin: 0 auto;
   p {
      font-size: 1rem;
   }
   label {
      margin: 1.25rem 0;
   }
   input, select, textarea {
      margin-top: 1rem;
      &:focus {
         box-shadow: none;
      }
   }
   button {
      padding: 0.75rem;
      font-size: 1.2rem;
      border: 0.2rem solid;
      border-radius: 0.5rem;
      cursor: pointer;
      background-color: transparent !important;
      @include warning_color;
      @include border_warning_color;
      &:hover {
         background-color: $secondary_color !important;
      }
      &:disabled {
         cursor: not-allowed;
         &:hover {
            background-color: $transparent_white !important;
         }
      }
   }
   input[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      width: 5rem!important;
      margin: 0 0.75rem;
      border-width: 2px;
      border-radius: 0.5rem;
      @include border_grey_color;
      &:hover {
         @include border_warning_color;
      }
      &:focus {
         @include border_success_color;
      }
      @include mobile {
         width: 15% !important;
      }
   }
   input[type="number"]::-webkit-inner-spin-button,
   input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
   }
   small {
      display: block;
      margin-top: 0.5rem;
      @include responsive_font_size(1rem, 1rem, 1rem, 1rem, 1rem);
      @include text_simple_style(400, left);
      @include main_font_color;
   }
   .select_label {
      display: inline-block;
      margin: 1rem 0 0.5rem 0;
   }
   .event_info_section {
      margin-top: 1.5rem;
   }
   .event_info_title {
      display: inline-block;
      margin-top: 2rem;
      margin-bottom: 0;
      font-size: 1.25rem;
   }
   .event_info_label {
      margin: 0.75rem 0;
   }
   .duration_label {
      display: inline-block;
   }
   .text_input, .select_input, .number_input, .textarea_input {
      width: 100%;
      height: calc(1.5em + .75rem + 2px);
      padding: .375rem .75rem;
      line-height: 1.5;
      font-size: 1rem;
      background-color: transparent;
      background-clip: padding-box;
      border-radius: .25rem;
      border: 0.1rem solid;
      outline: none;
      @include border_main_color;
      @include main_font_color;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   }
   .select_input {
      font-size: 1rem;
      border: 0.1rem solid;
      @include border_main_color;
      border-radius: 0.5rem;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      &:hover {
         @include border_warning_color;
      }
      &:focus {
         @include border_success_color;
         option {
            background-color: $main_color;
         }
      }
   }
   .form_input {
      position: relative;
      margin-bottom: 1rem;
      margin-top: 1.5rem;
      .form-control:focus {
         box-shadow: none;
         outline: none;
      }
      .text_input {
         position: relative;
         padding: 1.25rem 1rem !important;
         border-width: 2px;
         background: transparent none;
         @include border_grey_color;
         border-radius: 0.5rem;
         outline: none;
         &:hover {
            @include border_warning_color;
         }
         &:focus {
            @include border_success_color;
         }
      }
      label {
         background-color: transparent;
         position: absolute;
         left: 1rem;
         top: 0.5rem;
         font-size: 1rem;
         cursor: text;
         transition: top 200ms ease-in,
                     left 200ms ease-in,
                     font-size 200ms ease-in;
      }
      .text_input:focus ~ label,
      .text_input:not(:placeholder-shown):not(:focus) ~label {
         top: -2rem;
         left: 1rem;
         background-color: transparent;
      }
   }
   .privacy {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      & label:first-child {
         display: block;
      }
      .privacy_form_group {
         margin-bottom: 0;
         div {
            display: flex;
            flex-direction: row;
            margin-left: 2.2rem;
            input {
               -moz-appearance: none;
               -webkit-appearance: none;
               appearance: none;
               position: static;
               vertical-align: middle;
               width: 1.25rem;
               height: 1.25rem;
               margin-top: 0;
               background-color: white;
               font-size: inherit;
               border-radius: 50%;
               border: 0.2rem solid #555;
               cursor: pointer;
               outline: none;
            }
            input:checked {
               background: $success_color;
            }
            p {
               margin-left: 0.3rem;
               margin-bottom: 0;
               @include main_font_color;
            }
         }
      }
   }
   .textarea_group {
      position: relative;
      margin: 2rem 0;
      .textarea_input {
         position: relative;
         height: fit-content;
         min-height: 10rem;
         @include large_screen {
            min-height: 10rem;
         }
         @include desktop {
            min-height: 8rem;
         }
         @include tablet {
            min-height: 7rem;
         }
         @include mobile {
            min-height: 6rem;
         }
         background: none;
         border-width: 2px;
         @include border_grey_color;
         border-radius: 0.5rem;
         resize: none;
         &:hover {
            @include border_warning_color;
         }
         &:focus {
            @include border_success_color;
            box-shadow: none;
         }
      }
      label {
         position: absolute;
         left: 1rem;
         top: 1rem;
         cursor: text;
         transition: top 200ms ease-in,
                     left 200ms ease-in,
                     font-size 200ms ease-in;
      }
      .textarea_input:focus ~ label,
      .textarea_input:not(:placeholder-shown):not(:focus) ~label {
         top: -2rem;
         left: 1rem;
         margin-top: 1.4rem;
         padding: 0 0.3rem;
         background-color: transparent;
      }
   }
   .duration {
      display: inline !important;
   }
   .contact_us_alert {
      width: 100%;
      margin-top: 1.5rem;
      padding: 1rem;
      @include main_font_color;
      font-size: 1.2rem;
      font-weight: 500;
      border-radius: 1rem;
      opacity: 0.8;
   }
   .alert_success {
      background-color: $darker_success_color;
   }
   .alert_error {
      @include danger_color;
   }
}
