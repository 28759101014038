#help-view {
   @include mobile {
      padding: 0 4%;
   }
   p {
      color: white;
      text-align: justify;
   }
}

.help__link {
   background-color: transparent;
   z-index: 1400;
   margin: 1rem;
   @include text_basic_style(white, 1.5rem, 600, center);
   a {
      @include generic_button($secondary_color, 0, inherit);
      color: inherit;
      border-radius: 1rem;
      padding: 0.5rem;
      &:hover {
         background-color: $darker_secondary_color;
         text-decoration: none;
      }
   }
   &:hover {
      background-color: darken(white, 10%);
   }
}
