$grey_color: #C8C8C8;
$main_color: #210023;
$secondary_color: #6F0072;
$info_color: #50ABD8;
$waring_color : #FFF74A;
$success_color: #00E55C;
$danger_color: #F65843;
$dark_grey: #272b34;

$lighten_secondary_color: lighten($secondary_color, 50%);

$darker_secondary_color: darken($secondary_color, 10%);
$darker_info_color : darken($info_color, 10%);
$darker_waring_color : darken($waring_color, 18%);
$darker_success_color: darken($success_color, 10%);
$darker_danger_color: darken($danger_color, 10%);

$transparent_white: rgba(255, 255, 255, 0.1);
$transparent_grey: rgba(105, 105, 105, 0.1);

$main_font_color: #ffffff;
$main_inverse_font_color: black;
$complementary_color: #0F0523;
$grey_variant_color: #C8C8C8;
