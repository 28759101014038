footer {
   @include footer_background;
   padding: 1rem 0 3rem 0 !important;
   @include mobile {
      padding: 1rem 0 6rem 0 !important;
   }
   .footer {
      max-width: 1075px;
      margin: 5rem auto !important;
      h1 {
         margin: 0;
         padding: 2rem 0;
         @include responsive_font_size(2.5rem, 2.5rem, 2.5rem, 2.5rem, 2.5rem);
         @include text_simple_style(700, center);
         @include darker_success_color;
         @include mobile {
            padding: 1vw 4vw;
            padding-top: 0;
         }
      }
      .footer__request-demo--container {
         display: flex;
         justify-content: center;
         width: 100%;
         padding: 2rem 0;
      }
      .footer__items {
         display: flex;
         flex-direction: row;
         justify-content: center;
         padding: 2.5rem 0;
         width: 75%;
         margin: 0 auto;
         @include mobile {
            width: 90%;
         }
         div {
            display: flex;
            flex-direction: column;
            align-content: center;
            margin: 0 auto;
            .footer___topic-title {
               margin-bottom: 0;
               @include responsive_font_size(1rem, 1rem, 1rem, 1rem, 1rem);
               @include text_simple_style(normal, left);
               @include darker_success_color;
            }
            .footer_topic_item {
               margin-bottom: 0;
               text-decoration: none;
               cursor: pointer;
               @include responsive_font_size(1rem, 1rem, 1rem, 1rem, 1rem);
               @include text_simple_style(300, left);
               @include main_font_color;
               &:hover {
                  @include lighten_secondary_color;
               }
            }
         }
      }
      .footer__logo-container {
         display: flex;
         justify-content: center;
         padding: 2rem 0;
         .footer_logo {
            margin: 0 auto !important;
            max-width: 40%;
            min-width: 40%;
            @include ecclesia_footer_logo_filter;
            @include mobile {
               min-width: 70%;
               max-width: 70%;
            }
         }
      }
      .footer__icons-container {
         padding: 1rem 0;
         @include mobile {
            padding: 5vw 0;
         }
         div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 25%;
            margin: 0 auto;
            @include mobile {
               width: 30%;
            }
            a {
               font-size: 2.25rem !important;
               margin: 0.5rem;
               @include grey_color;
               &:hover {
                  @include main_font_color;
               }
               @include mobile {
                  font-size: 2.5rem !important;
               }
            }
         }
      }
   }
}
