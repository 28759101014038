@import '~video-react/styles/scss/video-react';

#resources-view {
   #infographics {
      margin-top: 0;
      a {
         text-decoration: none;
         &:hover {
            text-decoration: none;
         }
      }
   }
}
