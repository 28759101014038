#home-view, #use-cases-view  {
   h1 {
      @include responsive_font_size(2.3rem, 2.8rem, 2rem, 2.2rem, 3rem);
   }
   h2 {
      margin: 0 0 3rem 0!important;
      line-height: 1;
      @include responsive_font_size(2.5rem, 2.5rem, 2rem, 2.2rem, 3rem);
      @include mobile {
         @include text_simple_style(600, center!important);
      }
      @include tablet {
         @include text_simple_style(600, center!important);
      }
      @include desktop {
         font-weight: 500;
      }
      @include large_screen {
         font-weight: 500;
      }
      @include extra_large_screen {
         font-weight: 500;
      }
   }
   h3 {
      @include responsive_font_size(1.3rem, 1.3rem, 1.1rem, 1.2rem, 1.6rem);
      @include text_simple_style(300, center !important);
      @include desktop {
         line-height: 1.2;
      }
      @include large_screen {
         line-height: 1.2;
      }
      @include extra_large_screen {
         line-height: 1.2;
      }
   }
   p {
      line-height: 1.5;
      @include responsive_font_size(1.1rem, 1.1rem, 1rem, 1.2rem, 1.2rem);
      @include mobile {
         margin: 2rem 1rem !important;
         text-align: center !important;
      }
      @include tablet {
         margin: 2rem 1rem !important;
         text-align: center !important;
      }
   }
   #home__ecclesia-features, #use-cases__features, #home__assemblies-features {
      @include home_ecclesia_features_background;
      .home-view__feature--container {
         @include mobile {
            padding: 1rem 0;
            margin-bottom: 6rem;
         }
         @include tablet {
            padding: 3rem 0;
            margin-bottom: 6rem;
         }
         @include desktop {
            padding: 6rem 0;
         }
         @include large_screen {
            padding: 6rem 0;
         }
         @include extra_large_screen {
            padding: 6rem 0;
         }
         .home-view__feature-text-container {
            padding: 3rem;
            border-radius: 30px;
            @include blur_dark_background;
         }
         .home-view__feature-image-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            @include mobile {
               height: calc(0.75 * (100vw - 4rem));
               justify-content: flex-start;
            }
            @include tablet {
               height: calc(0.75 * (100vw - 8rem));
               justify-content: flex-start;
            }
            @include desktop {
               height: 280px;
            }
            @include large_screen {
               height: 350px;
            }
            @include extra_large_screen {
               height: 400px;
            }
            .home-view__feature--image {
               max-width: 100%;
               @include mobile {
                  height: calc(0.75 * (100vw - 4rem));
               }
               @include tablet {
                  height: calc(0.75 * (100vw - 8rem));
               }
               @include desktop {
                  max-height: 280px;
               }
               @include large_screen {
                  max-height: 350px;
               }
               @include extra_large_screen {
                  max-height: 400px;
               }
               @include fade_in_from_bottom(0.7s);
            }
         }
      }
   }
}

#home__banner {
   display: flex;
   flex-direction: column;
   justify-content: center;
   h1 {
      @include main_font_color;
   }
   .home-banner__ecclesia-web-app--image {
      width: 100%;
      @include mobile {
         margin-top: 1rem;
      }
      @include tablet {
         margin-top: 1rem;
      }
   }
}

#home__why-ecclesia--section {
   @include home_why_ecclesia_background;
   background-position: top;
   background-repeat: no-repeat;
   background-size: cover;
   h2 {
      @include text_simple_style(600, center);
      @include success_color;
   }
   p {
      text-align: center;
   }
}

#home__reviews-container{
   background-position: top;
   background-repeat: no-repeat;
   background-size: cover;
   @include home_why_ecclesia_background;
}

#home__benefits {
   @include home_why_ecclesia_background;
}
#home-benefits__pricing-button {
   @include home__why-us--button();
   margin: 3rem 0 0 0;
}
