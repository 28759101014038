#blog-index-view {
  #blog__carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      text-align: center;
      text-transform: uppercase;
      @include responsive_font_size(1.4rem, 1.4rem, 1.5rem, 1.5rem, 1.5rem);
      @include mobile {
        font-weight: 800;
        text-align: left !important;
        margin: 2rem 1rem 1rem 1rem;
      }
      @include tablet {
        font-weight: 800;
        text-align: left !important;
        margin: 2rem 1rem 1rem 1rem;
      }
      @include desktop {
        font-weight: 900;
      }
      @include large_screen {
        font-weight: 900;
      }
      @include extra_large_screen {
        font-weight: 900;
      }
    }
    p {
      text-align: left !important;
      font-weight: 300;
      @include responsive_font_size(1.1rem, 1.1rem, 1.2rem, 1.2rem, 1.2rem);
      @include mobile {
        line-height: 1.5;
      }
      @include tablet {
        line-height: 1.5;
      }
      @include desktop {
        line-height: 1.2;
        margin: 1rem;
      }
      @include large_screen {
        line-height: 1.2;
        margin: 1rem;
      }
      @include extra_large_screen {
        line-height: 1.2;
        margin: 1rem;
      }
    }
  }
}

.blog-entry__container {
  img {
    width: 100%;
  }
  ol {
    list-style-type: upper-roman;
    padding-left: 3rem;
  }
  h1 {
    line-height: 1.2;
    @include responsive_font_size(2.3rem, 2.3rem, 2.5rem, 2.5rem, 2.5rem);
  }
  h2 {
    @include responsive_font_size(1.5rem, 1.5rem, 2.5rem, 2.5rem, 2.6rem);
  }
  h4 {
    text-align: left;
  }
  p {
    line-height: 1.5;
    @include responsive_font_size(1.1rem, 1.1rem, 1.2rem, 1.2rem, 1.2rem);
  }
  li {
    @include desktop {
      line-height: 1.5;
      font-size: 1.2rem;
    }
    @include mobile {
      line-height: 1.5;
      font-size: 1.1rem;
    }
  }
  .blog-entry__body {
    #body_viable_systems_img {
      margin: 2rem 0;
      @include large_screen {
        width: 35%;
      }
      @include desktop {
        width: 40%;
      }
    }
  }
}






