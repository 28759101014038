#terms-of-use-and-conditions-view, #data-privacy-policy-view {
   @include basic-links;
   @include wrap-break-word;
   display: flex;
   flex-direction: column;
   .content-container {
      @include background_transparent_dark_color;
      backdrop-filter: blur(5px);
   }
   section {
      padding: 0 2rem;
      margin-bottom: 4rem;
   }
   p {
      text-align: justify;
   }
   .list-lower-alfa {
      list-style-type: lower-alpha;
   }
}
