.button {
   a {
      display: flex !important;
      justify-content: center;
      align-items: center;
      color: inherit !important;
      width: 100% !important;
      height: 100% !important;
      padding: 5%;
      &:hover {
         text-decoration: none !important;
      }
   }
   &:hover {
      transform: scale(1.1);
      transition: transform 1.4s ease;
      cursor: pointer;
   }
   &:not(:hover) {
      transform: scale(1);
      transition: transform 0.7s ease;
   }
}
.dropdown_button {
   height: fit-content;
   padding: 0 0.4rem;
   border-radius: 0 0.25rem 0.25rem 0;
   font-size: 3rem;
   @include grey_color;
   &:hover {
      cursor: pointer;
   }
}
.start-ecclesia--button {
   @include responsive_font_size(1.4rem, 1.4rem, 1.4rem, 1.4rem, 1.4rem);
   @include text_simple_style(500, center);
   @include warning_color;
   @include basic_shape(0, 2rem 0 2rem 0);
   background-color: transparent;
   border-radius: 1rem;
   height: 5rem;
   @include tablet {
      height: 6rem;
   }
   @include mobile {
      height: 6rem;
   }
   a {
      padding: 1.2rem 1.5rem;
   }
}
@mixin yellow-button {
   border-radius: 1rem;
   width: 100%;
   color: $main_color;
   @include responsive_font_size(1.2rem, 1.3rem, 1rem, 1.2rem, 1.5rem);
   @include text_simple_style(600, center);
   @include basic_shape(0, 1rem 0);
   @include background_darker_waring_color;
}
.request-demo--button {
   border-radius: 1rem;
   width: 100%;
   min-height: 3.5rem;
   @include responsive_font_size(1.2rem, 1.3rem, 1rem, 1.2rem, 1.5rem);
   @include text_simple_style(600, center);
   @include basic_shape(0, 1rem 0);
   @include background_warning_color;
   color: $main_color;
}
#home-banner__request-demo--button {
   &.visibility-hidden {
      visibility: hidden;
   }
}
#home-banner__request-demo-button--bottom {
   display: none;
   flex-direction: column;
   justify-content: center;
   flex-wrap: wrap;
   position: fixed;
   width: fit-content;
   margin: 0;
   min-width: 15rem;
   min-height: 3.5rem;
   bottom: 2rem;
   right: 5vw;
   z-index: 80;
   -webkit-text-size-adjust: 100%;
   &.display-flex {
      display: flex;
   }
   @include responsive_font_size(1.2rem, 1.3rem, 1rem, 1.2rem, 1.5rem);
   @include mobile {
      display: none !important;
   }
   @include fade_in_from_top_left(0.4s);
}

@mixin home__why-us--button {
   @include basic_shape(0, 2rem 0 2rem 0);
   @include background_warning_color;
   @include border_warning_color;
   @include responsive_font_size(1.2rem, 1.3rem, 1rem, 1.2rem, 1.5rem);
   @include text_simple_style(500, center);
   width: 100%;
   height: 4rem;
   border: 0.2rem solid;
   border-radius: 1rem;
   a {
      padding: 1.2rem 1.5rem;
   }
}
#home-why-us__pricing--button {
   @include home__why-us--button();
}
#home-benefits__pricing--button {
   @include yellow-button();
}

@mixin pricing__card-pricing--button {
   width: 100%;
   height: 4rem;
   border-radius: 0 0 1.5rem 1.5rem;
   margin: 0 !important;
   display: flex;
   justify-content: center;
   align-items: center;
   @include responsive_font_size(1.4rem, 1.4rem, 1.4rem, 1.4rem, 1.4rem);
}
#pricing__free-start-ecclesia--button {
   @include pricing__card-pricing--button();
   color: $main_font_color;
   background-color: $secondary_color;
}
#pricing__subscription-request-demo--button {
   @include pricing__card-pricing--button();
   color: $main_inverse_font_color;
   background-color: $secondary_color;
}

#footer__request-demo--button {
   color: $main_color;
   @include background_warning_color;
   width: 35% !important;
   @include tablet {
      width: 40% !important;
   }
   @include mobile {
      width: 60% !important;
   }
}
