.blog-carousel__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  transition: transform 2s;
  @include blur_dark_background;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  h3 {
    @include responsive_font_size(1.5rem, 1.5rem, 1.5rem, 1.5rem, 1.5rem);
    @include text_simple_style(600, center);
  }
  p {
    @include responsive_font_size(1.2rem, 1.2rem, 1.2rem, 1.2rem, 1.2rem);
    @include text_simple_style(100, left);
    @include main_font_color;
  }
  .card__image {
    width: 100%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .card__body {
    height: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card__footer, {
      bottom: 0;
      @include responsive_font_size(0.9rem, 0.9rem, 0.9rem, 0.9rem, 0.9rem);
      @include text_simple_style(300, right);
      @include secondary_light_warning_dark;
    }
  }
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  height: fit-content;
  @include blur_dark_background;
  .card__body {
    width: 100%;
    height: fit-content;
    padding: 2rem;
    word-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
    @include custom_scroll_bar;
    h2 {
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
      margin: 1rem 0;
    }
    h5 {
      margin: 1rem 0;
    }
    p {
      line-height: 1.5;
      @include responsive_font_size(1.1rem!important, 1.1rem!important, 1rem!important, 1.2rem!important, 1.2rem!important);
      @include text_simple_style(300!important, center!important);
      @include main_font_color;
    }
    tbody {
      th {
        line-height: 1.2;
        @include responsive_font_size(1.1rem!important, 1.1rem!important, 1rem!important, 1.2rem!important, 1.2rem!important);
      }
    }
    .card-body__subtitle{
      line-height: 1.5;
      @include responsive_font_size(1.1rem!important, 1.1rem!important, 1rem!important, 1.2rem!important, 1.2rem!important);
      @include text_simple_style(400!important, left!important);
      @include secondary_light_warning_dark;
    }
    .card-body__header{
      line-height: 1.5;
      @include responsive_font_size(1.3rem!important, 1.3rem!important, 1.1rem!important, 1.2rem!important, 1.6rem!important);
      @include text_simple_style(600!important, center!important);
    }
    .card-body__avatar {
      height: auto;
      width: 100%;
      max-width: 7rem;
      border-radius: 50%;
      object-fit: cover;
    }
    .card-body__footer {
      display: flex;
      justify-content: flex-end;
      height: fit-content;
      width: 100%;
      h6 {
        width: 100%;
        line-height: 1.5;
        margin: 1rem 0;
        @include responsive_font_size(1.1rem!important, 1.1rem!important, 1rem!important, 1.2rem!important, 1.2rem!important);
        @include text_simple_style(500!important, center!important);
        @include main_font_color;
      }
    }
  }
  .card__footer{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    h6 {
      width: 100%;
      line-height: 1.5;
      @include responsive_font_size(1.1rem!important, 1.1rem!important, 1rem!important, 1.2rem!important, 1.2rem!important);
      @include text_simple_style(500!important, center!important);
      @include main_font_color;
    }
  }
}
.benefits__cards-container {
  min-height: 24rem;
}
.card.card--benefit {
  min-height: 23rem;
}
.card.card--pricing {
  #card-pricing__request-quotation-button {
    @include pricing__card-pricing--button();
  }
}
