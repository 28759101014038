@mixin translucent_grey_background {
  background-color: $transparent_grey !important;
  backdrop-filter: blur(3rem) !important;
}
@mixin translucent_black_background {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
}
@mixin translucent_white_background {
  background-color: rgba($main_color_light, 0.6);
  backdrop-filter: blur(0.5rem);
}
@mixin blur_dark_background {
  [data-theme="light"] & {    
    background: linear-gradient(0deg, rgba($main_color_light, 0.48), rgba(255, 255, 255, 0.12)) !important;
    filter: drop-shadow(0 4px 4px $main_color_light);
  }
  [data-theme="dark"] & {
    background: linear-gradient(0deg, rgba(#323743, 0.12), rgba($dark_grey, 0.48)) !important;
    filter: drop-shadow(0 4px 4px $dark_grey);
  }
  backdrop-filter: blur(8px);
}

@mixin header_background {
  [data-theme="light"] & {
    @include translucent_white_background;
  }
  [data-theme="dark"] & {
    @include translucent_black_background;
  }
}
@mixin footer_background {
  background-repeat: no-repeat;
  background-size: cover;
  @include mobile {
    background-position: left;
  }
  @include tablet {
    background-position: left;
  }
  @include desktop {
    background-position: center;
  }
  @include large_screen {
    background-position: center;
  }
  @include extra_large_screen {
    background-position: center;
  }
  [data-theme="light"] & {
    background-image: url('../../images/home/banner/retro-futuristic-agora.jpg');
  }
  [data-theme="dark"] & {
    background-image: url('../../images/home/banner/banner_background.jpg');
  }
}
@mixin first_section_background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  [data-theme="light"] & {
    background-image: url('../../images/home/banner/retro-futuristic-agora.jpg');
  }
  [data-theme="dark"] & {
    background-image: url('../../images/home/banner/banner_background.jpg');
  }
}
@mixin home_ecclesia_features_background {
  [data-theme="light"] & {
    background-color: white;
    background-image: url("../../images/home/purple_network_background.png");
    background-position: top;
    background-repeat: repeat-y;
  }
  [data-theme="dark"] & {
    background-image: url("../../images/home/home_background.jpg");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
}
@mixin home_why_ecclesia_background {
  [data-theme="light"] & {
    background-color: white;
  }
  [data-theme="dark"] & {
    background-image: url("../../images/home/why_ecclesia_background.jpg");
  }
}

@mixin ecclesia_logo_filter {
  [data-theme="light"] & {
    filter: brightness(40%) grayscale(100%);
    &:hover {
      filter: brightness(0%) grayscale(100%);
    }
  }
  [data-theme="dark"] & {
    filter: brightness(1000%) grayscale(100%);
    &:hover {
      filter: none;
    }
  }
}
@mixin ecclesia_logo_image_filter {
  [data-theme="light"] & {
    filter: brightness(30%) grayscale(100%);
  }
}
