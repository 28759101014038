@mixin mobile {
   @media (max-width: 600px) {
      @content;
   }
}

@mixin tablet {
   @media (min-width: 600px) and (max-width: 900px) {
      @content;
   }
}

@mixin desktop {
   @media (min-width: 900px) and (max-width: 1200px)  {
      @content;
   }
}

@mixin large_screen {
   @media (min-width: 1200px) and (max-width: 1535px){
      @content;
   }
}

@mixin extra_large_screen {
   @media (min-width: 1536px) {
      @content;
   }
}

@mixin responsive_font_size($mobile, $tablet, $desktop, $large_screen, $extra_large_screen) {
   @include mobile {
      font-size: $mobile;
   }
   @include tablet {
      font-size: $tablet;
   }
   @include desktop {
      font-size: $desktop;
   }
   @include large_screen {
      font-size: $large_screen;
   }
   @include extra_large_screen {
      font-size: $extra_large_screen;
   }
}

@mixin text_simple_style(
   $weight: normal,
   $alignment: left
) {
   font-weight: $weight;
   text-align: $alignment;
}
@mixin text_basic_style(
   $color: white,
   $size: 1rem,
   $weight: normal,
   $alignment: left
) {
   color: $color;
   font-size: $size;
   font-weight: $weight;
   text-align: $alignment;
}

@mixin responsive_width($mobile, $tablet, $desktop, $large_screen, $extra_large_screen) {
   @include mobile {
      width: $mobile;
   }
   @include tablet {
      width: $tablet;
   }
   @include desktop {
      width: $desktop;
   }
   @include large_screen {
      width: $large_screen;
   }
   @include extra_large_screen {
      width: $extra_large_screen;
   }
}
