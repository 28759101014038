.table-container {
  display: flex;
  justify-content: center;
  height: fit-content;
  width: 100% !important;
  padding: 1rem;
  overflow-x: auto;
}
table {
  @include main_font_color;
  width: fit-content;
  height: fit-content;
  display: block;
  overflow-x: auto;
  border-spacing: 0;
  thead  {
    th {
      text-align: center;
      padding: 1rem;
      border: 1px solid;
      @include border_main_color;
    }
  }
  tbody{
    white-space: nowrap;
    td {
      padding: 1rem;
      text-align: left;
      border: 1px solid;
      @include border_main_color;
    }
  }
}
