html {
   font-size: 16px;
   @include mobile {
      font-size: 12px;
   }
   @include tablet {
      font-size: 14px;
   }
}

* {
   box-sizing: border-box;
   border: none;
}

body {
   text-rendering: optimizeLegibility;
   @include scroll_bar(15%, 5%);
   margin: 0;
}

#root {
   overflow: hidden;
}

.mobile {
   display: none;
   @include mobile {
      display: inline;
   }
}

.not_mobile {
   @include mobile {
      display: none;
   }
}

a {
   text-decoration: none;
   &:hover {
      text-decoration: underline;
   }
}
