.dropdown-box--container {
   width: 100%;
   display: flex;
   flex-direction: column;
   margin-right: auto;
   @include main_font_color;
   @include mobile {
      margin: 1rem auto;
   }
   header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      backdrop-filter: blur(0.5rem);
      font-weight: 100;
      border-radius: 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      @include background_transparent_light_color;
      @include main_font_color;
      label {
         text-align: left;
         @include grey_color;
         padding: 0.75rem 0 0.75rem 1.5rem;
         cursor: pointer;
      }
   }
   section {
      @include background_transparent_dark_color;
      backdrop-filter: blur(5px);
      padding: 1rem 2rem;
      p {
         margin-bottom: 0;
         text-align: justify;
         font-size: 1.2rem;
         padding: 1.2rem 1rem !important;
         br {
            display: block;
            line-height: 15rem;
            @include mobile {
               margin: 1rem !important;
            }
         }
         b {
            font-size: 1rem;
            font-weight: 500;
         }
      }
      ol {
         font-size: 1.2rem;
         @include mobile {
            margin-bottom: 2rem;
         }
         li {
            counter-increment: li;
            display: list-item;
            margin-top: 0.5rem;
            margin-left: 4rem;
            font-size: 1rem;
            @include main_font_color;
            span {
               margin-left: 0.6rem;
               @include mobile {
                  margin-left: 1rem;
               }
            }
         }
         li::marker {
            content: counter(li) ')';
            font-weight: 600;
            @include main_font_color;
         }
      }
      ul {
         padding: 1rem 2rem;
         @include mobile {
            padding: 2.7vw 4vw;
         }
         li {
            width: fit-content;
            margin-top: 0.5rem;
            list-style: none;
            @include mobile {
               margin-top: 4vw;
            }
            a {
               height: 100%;
               font-size: 1.05rem;
               font-weight: 600;
               text-decoration: none;
               @include main_font_color;
               svg {
                  margin-left: 0.5rem;
               }
               &:hover {
                  @include background_darker_secondary_color;
                  @include inverse_main_color_light_mode;
                  text-decoration-style: wavy;
               }
            }
         }
         li:first-child {
            margin-top: 0;
         }
      }
      hr {
         margin: 0;
         border-width: 0.15rem;
         @include mobile {
            border-width: 0.3vw;
         }
      }
      h5 {
         font-size: 1.5rem;
         font-weight: 600;
         padding: 1rem;
         margin-bottom: 0;
         @include main_font_color;
         &:hover {
            @include background_darker_secondary_color;
            @include inverse_main_color_light_mode;
            cursor: pointer;
         }
      }
      .video-react-big-play-button {
         width: 8rem;
         height: 4.75rem;
         font-size: 2.8rem;
      }
      .video-react-control-bar {
         height: 3rem;
         font-size: 1rem;
      }
      & hr:last-child {
         display: none;
      }
      a {
         text-decoration: none;
         @include secondary_light_warning_dark;
         &:hover {
            text-decoration: underline;
         }
      }
   }
}
