.customer_support {
   position: fixed;
   bottom: 3rem;
   right: 3rem;
   width: fit-content;
   z-index: 75;
   @include mobile {
      bottom: 4vw;
      right: 4vw;
   }
   button {
      font-size: 2rem;
      width: 6rem;
      height: 4rem;
      border-radius: 2rem;
      color: white;
      background-color: $grey_color;
      &:hover {
         box-shadow: 0.2rem 0.2rem 0.5rem #d3d1d1, -0.2rem -0.2rem 0.5rem #d3d1d1;
         cursor: pointer;
         transform: scale(1.1);
      }
      @include mobile {
         width: 14vw;
         height: 10vw;
      }
   }
}

.contact_modal {
   overflow-y: scroll;
   .contact_modal_background {
      width: fit-content !important;
      margin: 4rem auto;
   }
   .contact_modal_container {
      max-width: 80vw;
      width: 45rem !important;
      padding: 2rem 3rem 3rem 3rem !important;
      border-radius: 2rem;
      @include translucent_grey_background();
      @include mobile {
         width: 90vw !important;
         max-width: 90vw;
         margin-top: 5vh;
         padding: 0.5rem !important;
      }
      h5 {
         margin: 0.75rem auto;
         text-align: center;
         color: white !important;
         font-size: 2rem;
         font-weight: 600;
         @include mobile {
            font-size: 5.5vw;
         }
      }
   }
}
