@mixin grey_color {
  [data-theme="light"] & {
    color: $grey_color_light;
  }
  [data-theme="dark"] & {
    color: $grey_color;
  }
}
@mixin main_font_color {
  [data-theme="light"] & {
    color: $main_font_color_light;
  }
  [data-theme="dark"] & {
    color: $main_font_color;
  }
}
@mixin secondary_color {
  [data-theme="light"] & {
    color: $secondary_color_light;
  }
  [data-theme="dark"] & {
    color: $secondary_color;
  }
}
@mixin info_color {
  [data-theme="light"] & {
    color: $info_color_light;
  }
  [data-theme="dark"] & {
    color: $info_color;
  }
}
@mixin warning_color {
  [data-theme="light"] & {
    color: $waring_color_light;
  }
  [data-theme="dark"] & {
    color: $waring_color;
  }
}
@mixin success_color {
  [data-theme="light"] & {
    color: $success_color_light;
  }
  [data-theme="dark"] & {
    color: $success_color;
  }
}
@mixin danger_color {
  [data-theme="light"] & {
    color: $danger_color_light;
  }
  [data-theme="dark"] & {
    color: $danger_color;
  }
}

@mixin inverse_main_color {
  [data-theme="light"] & {
    color: $main_inverse_font_color_light !important;
  }
  [data-theme="dark"] & {
    color: $main_inverse_font_color !important;
  }
}
@mixin darker_success_color {
  [data-theme="light"] & {
    color: $darker_success_color_light;
  }
  [data-theme="dark"] & {
    color: $darker_success_color;
  }
}
@mixin inverse_main_color_light_mode {
  [data-theme="light"] & {
    color: $main_font_color !important;
  }
  [data-theme="dark"] & {
    color: $main_font_color !important;
  }
}
@mixin lighten_secondary_color {
  [data-theme="light"] & {
    color: $lighten_secondary_color;
  }
  [data-theme="dark"] & {
    color: $lighten_secondary_color;
  }
}
@mixin secondary_light_warning_dark {
  [data-theme="light"] & {
    color: $secondary_color_light;
  }
  [data-theme="dark"] & {
    color: $waring_color;
  }
}

@mixin background_main_color {
  [data-theme="light"] & {
    background-color: $main_color_light;
  }
  [data-theme="dark"] & {
    background-color: $main_color;
  }
}
@mixin background_flip_main_color {
  [data-theme="light"] & {
    background-color: $main_color !important;
  }
  [data-theme="dark"] & {
    background-color: $main_color_light !important;
  }
}
@mixin background_inverse_main_color_light {
  [data-theme="light"] & {
    background-color: $main_inverse_font_color_light !important;
  }
  [data-theme="dark"] & {
    background-color: $main_inverse_font_color_light !important;
  }
}
@mixin background_secondary_color {
  [data-theme="light"] & {
    background-color: $secondary_color_light !important;
  }
  [data-theme="dark"] & {
    background-color: $secondary_color !important;
  }
}
@mixin background_warning_color {
  [data-theme="light"] & {
    background-color: $waring_color_light !important;
  }
  [data-theme="dark"] & {
    background-color: $waring_color !important;
  }
}
@mixin background_success_color {
  [data-theme="light"] & {
    background-color: $success_color_light !important;
  }
  [data-theme="dark"] & {
    background-color: $success_color !important;
  }
}
@mixin background_darker_success_color {
  [data-theme="light"] & {
    background-color: $darker_success_color_light !important;
  }
  [data-theme="dark"] & {
    background-color: $darker_success_color !important;
  }
}
@mixin background_lighten_main_color {
  [data-theme="light"] & {
    background-color: lighten($main_color_light, 5%) !important;
  }
  [data-theme="dark"] & {

    background-color: lighten($main_color, 5%) !important;
  }
}
@mixin background_darker_waring_color {
  [data-theme="light"] & {
    background-color: $darker_waring_color_light !important;
  }
  [data-theme="dark"] & {
    background-color: $darker_waring_color !important;
  }
}
@mixin background_darker_secondary_color {
  [data-theme="light"] & {
    background-color: $darker_secondary_color_light !important;
  }
  [data-theme="dark"] & {
    background-color: $darker_secondary_color !important;
  }
}
@mixin background_transparent_light_color {
  [data-theme="light"] & {
    background-color: $transparent_white_light !important;
  }
  [data-theme="dark"] & {
    background-color: $transparent_white !important;
  }
}
@mixin background_transparent_dark_color {
  [data-theme="light"] & {
    background-color: $transparent_grey_light !important;
  }
  [data-theme="dark"] & {
    background-color: $transparent_grey !important;
  }
}
@mixin background_complementary_color {
  [data-theme="light"] & {
    background-color: white !important;
  }
  [data-theme="dark"] & {
    background-color: $complementary_color !important;
  }
}
@mixin background_grey_variant_color {
  [data-theme="light"] & {
    background-color: $grey_variant_color_light;
  }
  [data-theme="dark"] & {
    background-color: $grey_variant_color;
  }
}

@mixin border_grey_color {
  [data-theme="light"] & {
    border-color: $grey_color_light;
  }
  [data-theme="dark"] & {
    border-color: $grey_color;
  }
}
@mixin border_main_color {
  [data-theme="light"] & {
    border-color: $main_font_color_light;
  }
  [data-theme="dark"] & {
    border-color: $main_font_color;
  }
}
@mixin border_inverse_main_color {
  [data-theme="light"] & {
    border-color: $main_inverse_font_color_light !important;
  }
  [data-theme="dark"] & {
    border-color: $main_inverse_font_color !important;
  }
}
@mixin border_warning_color {
  [data-theme="light"] & {
    border-color: $waring_color_light !important;
  }
  [data-theme="dark"] & {
    border-color: $waring_color !important;
  }
}
@mixin border_success_color {
  [data-theme="light"] & {
    border-color: $success_color_light !important;
  }
  [data-theme="dark"] & {
    border-color: $success_color !important;
  }
}

@mixin ecclesia_footer_logo_filter {
  [data-theme="light"] & {
    filter: brightness(0%) hue-rotate(291deg);
  }
}
