@mixin basic_shape($padding, $margin) {
  padding: $padding;
  margin: $margin;
}

@mixin generic_button($bg_color, $margin, $padding) {
  background-color: $bg_color;
  margin: $margin;
  padding: $padding;
}

@mixin responsive_sides_padding {
  padding-left: 6rem;
  padding-right: 6rem;
  @include extra_large_screen {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  @include large_screen {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  @include desktop {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  @include tablet {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @include mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@mixin  scroll_bar($darken_scroll, $darken_container) {
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    [data-theme="light"] & {
      box-shadow: inset 0 0 0.4rem
      darken($main_font_color_light, $darken_container);
    }
    [data-theme="dark"] & {
      box-shadow: inset 0 0 0.25rem
      darken($main_font_color, $darken_container);
    }
  }
  &::-webkit-scrollbar-thumb {
    @include background_grey_variant_color;
    [data-theme='light'] & {
      box-shadow: inset 0 0 0.4rem
      darken($main_font_color_light, $darken_scroll);
    }
    [data-theme='dark'] & {
      box-shadow: inset 0 0 0.25rem
      darken($secondary_color, $darken_scroll);
    }
    outline: none;
  }
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
  }
}
@mixin custom_scroll_bar {
  &::-webkit-scrollbar {
    width: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    [data-theme="light"] & {
      box-shadow: inset 0 0 0.2rem
      darken($main_font_color_light, 5%);
    }
    [data-theme="dark"] & {
      box-shadow: inset 0 0 0.3rem
      darken($main_font_color, 5%);
    }
  }
  &::-webkit-scrollbar-thumb {
    @include background_grey_variant_color;
    [data-theme='light'] & {
      box-shadow: inset 0 0 0.3rem
      darken($main_font_color_light, 15%);
    }
    [data-theme='dark'] & {
      box-shadow: inset 0 0 0.2rem
      darken($secondary_color, 15%);
    }
    outline: none;
  }
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
  }
}

@mixin basic-links {
  a {
    text-decoration: none;
    @include secondary_light_warning_dark;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
@mixin wrap-break-word {
  overflow-wrap: break-word;
  white-space: pre-line;
}
