$grey_color_light: #210023;
$main_color_light: #C8C8C8;
$secondary_color_light: #6F0072;
$info_color_light:  darken($info_color, 10%);
$waring_color_light : darken($waring_color, 18%);
$success_color_light: darken($success_color, 10%);
$danger_color_light: darken($danger_color, 10%);

$lighten_secondary_color: lighten($secondary_color_light, 25%);

$darker_secondary_color_light: darken($secondary_color_light, 10%);
$darker_info_color_light: darken($info_color_light, 10%);
$darker_waring_color_light: $waring_color_light;
$darker_success_color_light: darken($success_color_light, 5%);
$darker_danger_color_light: darken($danger_color_light, 10%);

$transparent_white_light: rgba(255, 255, 255, 0.2);
$transparent_grey_light: rgba(105, 105, 105, 0.07);

$main_font_color_light: #210023;
$main_inverse_font_color_light: white;
$complementary_color: #0F0523;
$grey_variant_color_light: #C8C8C8;

