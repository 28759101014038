#layout__header--top-menu {
   position: fixed;
   display: flex;
   justify-content: space-between;
   align-items: center;
   text-align: justify;
   top: 0;
   z-index: 30;
   width: 100vw;
   min-height: 6rem;
   padding-left: 3rem;
   padding-right: 3rem;
   @include mobile { padding-left: 1.5rem; padding-right: 1.5rem; }
   @include tablet { padding-left: 1.5rem; padding-right: 1.5rem; }
   &.header__translucent-background {
      @include header_background;
   }
   a {
      text-decoration: none;
   }
   .header__desktop-nav-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      @include tablet { display: none; }
      @include mobile { display: none; }
      .nav__item {
         text-transform: uppercase;
         align-items: center;
         padding: 0.5rem 1rem;
         margin: 0 0.2rem;
         border-radius: 1rem;
         background-color: transparent;
         cursor: pointer;
         z-index: 1000;
         @include responsive_font_size(0.9rem, 0.9rem, 0.9rem, 1rem, 1.1rem);
         @include text_simple_style(300, center);
         @include main_font_color;
         &:hover {
            @include background_main_color;
         }
         &.active {
            @include background_secondary_color;
            @include inverse_main_color_light_mode;
         }
      }
      .nav__menu {
         min-width: 8rem;
         width: fit-content;
         position: absolute;
         z-index: 1100;
         .nav__menu--paper {
            border-radius: 0.5rem;
            overflow: hidden;
            @include background_main_color;
            a {
               @include main_font_color;
            }
            .nav__menu--item {
               text-align: center !important;
               text-decoration: none !important;
               &:hover {
                  @include background_lighten_main_color;
               }
               .active {
                  @include background_secondary_color;
                  @include inverse_main_color_light_mode;
               }
               .nav__menu--link {
                  width: 100% !important;
                  height: 100% !important;
                  padding: 0.2rem 1rem;
               }
            }
         }
      }
      #nav__contact-us--button {
         border: 0.1rem solid;
         @include border_warning_color;
         &.active {
            color: black !important;
            @include background_warning_color;
         }
         &:hover {
            color: black !important;
               @include background_warning_color;
         }
      }
   }
   .header__mobile-nav-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include mobile {
         padding-left: 2rem;
      }
      @include tablet {
          padding-left: 6rem;
      }
      @include desktop { display: none; }
      @include large_screen { display: none; }
      @include extra_large_screen { display: none; }
      #mobile-nav-bar__login-button {
         @include main_font_color;
         &:hover {
            @include background_main_color;
         }
         @include mobile {
            margin-right: 1rem;
         }
         @include tablet {
            margin-right: 3rem;
         }
      }
   }
   #header__ecclesia-logo--image {
      width: 11rem;
      max-height: 80%;
      max-width: 100%;
      @include ecclesia_logo_filter;
   }
}
#mobile-menu {
   .MuiMenu-paper {
      border-radius: 0.5rem;
      @include background_main_color;
   }
   .MuiMenu-list {
      padding: 0;
   }
   .mobile-menu__item {
      font-size: 1.3rem;
      padding: 1rem 1rem;
      @include text_simple_style(300, left);
      &.MuiMenuItem-root {
         font-family: 'Space Grotesk', sans-serif !important;
         @include main_font_color;
      }
      &.Mui-selected {
         @include background_secondary_color;
         @include inverse_main_color_light_mode;
      }
   }
}
#layout__header-intersection--target {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 25;
   width: 5vw;
   height: 2rem;
}

.nav__language-options--menu {
   .MuiMenu-root  {
   }
   .MuiMenu-paper {
      @include translucent_grey_background();
      color: white;
      border-radius: 0.5rem;
      border: 0.1rem $waring_color solid;
      padding: 0.2rem 0.6rem;
      &:hover {
      }
   }
   .MuiMenu-list {
   }
   .nav__language-menu--item {
   }
}

#nav__login--button {
   @include generic_button(transparent, 0, inherit);
   @include main_font_color;
   &:hover {
      @include background_main_color;
   }
}

.MuiPaper-root {
   .MuiListItem-root {
      padding: 0;
   }
   #nav__mobile-language--button {
      width: 100%;
   }
   .nav__mobile-language-menu--item {
   }
   .responsive_link {
      height: fit-content !important;
      min-height: 0 !important;
      padding: 0 !important;
      justify-content: flex-end;
      @include tablet {
         display: block;
      }
      @include mobile {
         display: block;
      }
   }
   .dropdown_link {
      width: 100%;
      display: inline-block;
      padding: 0.5rem 1rem !important;
      color: $main_color !important;
      background-color: white;
      font-weight: 600;
      text-align: right !important;
      text-decoration: none;
      &.active {
         background-color: darken(white, 10%);
         color: inherit;
      }
   }
}

