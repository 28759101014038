h1, h2 {
  font-family: 'Space Grotesk', sans-serif !important;
}
h3, h4, h5, h6 {
  padding: 0;
  font-family: 'Ubuntu', sans-serif !important;
}
p, ol, li {
  font-family: 'Ubuntu', sans-serif !important;
  padding: 0;
  @include responsive_font_size(1.5rem, 1.5rem, 1.5rem, 1.5rem, 1.5rem);
  @include text_simple_style(300, left);
  @include main_font_color;
}
h1 {
  @include responsive_font_size(2.3rem, 2.8rem, 3rem, 3rem, 3.5rem);
  @include text_simple_style(600, center);
  @include secondary_light_warning_dark;
}
h2 {
  @include responsive_font_size(2rem, 2rem, 2.5rem, 2.5rem, 2.6rem);
  @include text_simple_style(600, center);
  @include secondary_light_warning_dark;
}
h3 {
  @include responsive_font_size(1.5rem, 1.8rem, 2rem, 2.5rem, 2.5rem);
  @include text_simple_style(400, left);
  @include secondary_light_warning_dark;
}
h4 {
  @include responsive_font_size(1.2rem, 1.5rem, 1.5rem, 2rem, 2rem);
  @include text_simple_style(400, left);
  @include secondary_light_warning_dark;
}
h5 {
  @include responsive_font_size(1.2rem, 1.3rem, 1.3rem, 1.5rem, 1.5rem);
  @include text_simple_style(400, left);
  @include secondary_light_warning_dark;
}
h6 {
  @include responsive_font_size(1.1rem, 1.2rem, 1.2rem, 1.3rem, 1.3rem);
  @include text_simple_style(400, left);
  @include secondary_light_warning_dark;
}
p {
  margin: 1rem 0;
}
a {
  cursor: pointer;
}
.view__section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include responsive_sides_padding;
}
.first-section {
  @include first_section_background;
  text-align: center;
  min-height: 100vh;
  padding-top: 6rem;
  padding-bottom: 3rem;
  @include responsive_sides_padding;
}
.height-75-vh {
  max-height: 75vh;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: auto;
  word-wrap: break-word;
  @include custom_scroll_bar;
}
.height-74-vh {
  height: 74vh;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: auto;
  @include custom_scroll_bar;
}
.text-left-not-mobile {
  @include extra_large_screen {
    text-align: left;
  }
  @include large_screen {
    text-align: left;
  }
  @include desktop {
    text-align: left;
  }
  @include tablet {
    text-align: left;
  }
}
.text-right-not-mobile {
  @include extra_large_screen {
    text-align: right;
  }
  @include large_screen {
    text-align: right;
  }
  @include desktop {
    text-align: right;
  }
  @include tablet {
    text-align: right;
  }
}
.visibility-hidden {
  visibility: hidden;
}
.display-flex {
  display: flex;
}
.fade-in-animation {
  @include fade_in_from_bottom(0.7s);
}
