#team--view {
  h2 {
    font-weight: 600;
    @include responsive_font_size(2.5rem, 2.5rem, 3rem, 3rem, 3rem);
    @include mobile {
      margin: 1rem;
    }
    @include tablet {
      margin: 1rem;
    }
  }
  h3 {
    font-weight: 500;
    @include responsive_font_size(1.5rem, 1.5rem, 2.3rem, 2.3rem, 2.3rem);
    @include mobile {
      text-align: left !important;
    }
    @include tablet {
      text-align: left !important;
    }
  }
  p {
    text-align: justify;
    line-height: 1.5;
    @include responsive_font_size(1.1rem, 1.1rem, 1.2rem, 1.2rem, 1.2rem);
    @include mobile {
      margin: 1rem 0 !important;
      text-align: left !important;
    }
    @include tablet {
      margin: 1rem 0 !important;
      text-align: left !important;
    }
  }
  #team__who-are-we {
    display: flex;
    flex-direction: column;
    justify-content: center;
    #team__ecclesia-logo-image {
      width: 100% !important;
      @include ecclesia_logo_image_filter;
    }
  }
  #team__our-team--section {
    @include background_complementary_color;
    #team__our-team--tittle {
      text-align: center !important;
    }
    h4 {
      @include desktop {
        line-height: 1.5;
        font-size: 1.2rem;
      }
    }
  }
  #team__our-purpose--section {
    @include background_complementary_color;
    #team__our-purpose--tittle {
      text-align: center !important;
    }
    #team__our-purpose--content {
      text-align: center !important;
      @include desktop {
          margin: 1rem 12rem;
      }
      @include large_screen {
          margin: 1rem 12rem;
      }
      @include extra_large_screen {
          margin: 1rem 12rem;
      }
    }
  }
  #team__our-values--section {
    @include background_complementary_color;
    #team__our-values--tittle {
      text-align: center !important;
    }
  }
}
