#layout {
  width: 100vw;
  #layout__whats-app--button {
    background-color: $success_color;
    color: white;
    position: fixed;
    height: 5rem;
    width: 5rem;
    bottom: 2rem;
    right: 5vw;
    z-index: 40;
    font-size: 4rem;
    border-radius: 4rem;
    &:hover {
      cursor: pointer;
    }
  }
}
