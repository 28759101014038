.manuals-view {
   a {
      text-decoration: none;
      @include text_basic_style(white, 1.5rem, 400, center);
      @include mobile {
         font-size: 3.25vw;
      }
      svg {
         margin-left: 0.5rem;
      }
      &:hover {
         color: $darker_waring_color;
         text-decoration-style: wavy;
      }
   }
}

