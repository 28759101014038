.checkbox_container {
   display: flex;
   flex-direction: row;
   margin: 1.15rem 0;
   @include mobile {
      margin: 5vw 0;
      font-size: 3.5vw;
   }
   input[type="checkbox"] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 1.2rem;
      height: 1.2rem;
      margin-top: 0;
      margin-left: 1rem;
      vertical-align: middle;
      font-size: inherit;
      @include background_inverse_main_color_light;
      border: 0.125vw solid #555;
      border-radius: 0.25vw;
      cursor: pointer;
      outline: none;
      @include mobile {
         width: 6vw;
         height: 6vw;
         border-radius: 0.5vw;
      }
   }
   input[type="checkbox"]:checked {
      background: rgb(236, 238, 248);
   }
   input[type="checkbox"]:checked:after {
      content: "✔";
      position: relative;
      top: -0.25rem;
      left: 0.0625rem;
      bottom: 0;
      font-size: inherit;
      font-weight: 500;
      @include success_color;
      @include mobile {
         top: 0.35vw;
         left: 1.25vw;
      }
   }
   p {
      margin-top: 0 !important;
      margin-left: 0.65rem !important;
      margin-bottom: 0 !important;
      color: white;
      @include mobile {
         margin-top: 0.75vw !important;
         margin-left: 2vw !important;
         font-size: 3.5vw;
      }
   }
}
